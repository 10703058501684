import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { mq } from '../utils/helpers';

import {
  Footer,
  FlatBoxes,
  Layout,
  Boxes,
  Slider,
  HomeText,
} from '../containers';
import { Container, HomeTextContainer, Header } from '../styled';
import styled from 'styled-components';

class AboutPageTemplate extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
  }

    getProjectData() {
        const { data } = this.props;
        return data.allMarkdownRemark.edges.filter(el =>  el.node.frontmatter.contener == this.props.pageContext.contener)[0].node.frontmatter;
    }

    getData(key) {
        const { data } = this.props;
        return data.allMarkdownRemark.edges.filter(el => el.node.frontmatter[key])[0].node.frontmatter;
    }

  render() {
    const { data } = this.props;
    const { slides } = this.getProjectData();
    const { contact } = this.getData('contact');

    return (
      <Layout renderWithChat>
        <Slider slides = {slides} />

          <HomeTextContainer>
              <Container>
              <AboutTextParagraph style={{marginTop:'2rem'}} size={28}>
              Привет! Измените бриф на что-то красивое!
              </AboutTextParagraph>
                  <AboutTextParagraph size={16}>
                  Все мы любим хорошо выглядеть. Ваша фирма заслуживает того же!
                  </AboutTextParagraph>
                  <AboutTextParagraph size={16}>
                  Когда сегодня Вы ведете бизнес на рынке, Вам нужно показать себя: видеоплатформы, стриминг, социальные сети, рекламная анимация, графические материалы... Все это поможет Вам найти идеального клиента и увеличить продажи.
                  </AboutTextParagraph>
                  <AboutTextParagraph size={16}>
                  Вот почему мы существуем. Чтобы позаботиться о том, как Вас видят. Мы занимаемся производством видероликов, дизайном и разработкой маркетинговых кампаний.
                  </AboutTextParagraph>
                  <AboutTextParagraph size={16}>
                  Нам нравится работать с людьми, и мы не боимся никаких бюджетов. Нашим специалистам уже доверили крупные бренды и новый бизнес. Нас ценят, потому что мы предоставляем полный комплекс услуг, от концепции до продвижения.
                  </AboutTextParagraph>
              </Container>
          </HomeTextContainer>

        <HomeTextContainer>
          <Container style={{marginTop:'2rem'}}>
              <Header>Наши клиенты:</Header>
          </Container>
        </HomeTextContainer>

          <HomeText>
          TCL  /  Hertz  /  NFM  /  Klinika Bocian  /  Stena Recycling  /  Gardena  /  BBC Earth  /  Fossil  /  IFB  /  Women in Tech Summit  /  Orange  /  Netflix  /  McFIT  /  Dom Medica  /
                    Warsaw Metro  /  Nocowanie.pl  /  Dr Gerard /  European Aquascaping Championship  /  Butcher's </HomeText>
          <HomeText marginTop={3}></HomeText>
        <Footer contactSectionContent={contact} />
      </Layout>
    );
  }
}

export default AboutPageTemplate;

const AboutTextParagraph = styled.p`
  font-size: ${props => props.size}px;
`;

export const query = graphql`
  query {
    filmy: file(relativePath: { eq: "filmy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 460, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    digital: file(relativePath: { eq: "digital.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    design: file(relativePath: { eq: "design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            contener
            slides {
                slide {
                    image_url
                    {
                      childImageSharp
                      {
                          fluid(maxWidth: 3080, quality: 100)
                          {
                            ...GatsbyImageSharpFluid
                          }
                      }
                    }
                    video_url
                    {
                        publicURL
                    }
                }
            }
            info {
               client
               description
               roles
            }
            projectboxes {
              row {
                box {
                  title
                  destination
                  image_url {
                    childImageSharp {
                      fluid(maxWidth: 3080, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }

                  hover_url {
                    publicURL
                  }

                 }
              }
            }
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
          }
        }
      }
    }
  }
`;
